.pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
    font-family: Arial, sans-serif;
  }
  
  .pagination p {
    display: flex;
    align-items: center;
    margin: 0;
  }
  
  .pagination button,
  .pagination span {
    background: none;
    border: none;
    color: #333;
    padding: 8px 12px;
    margin: 0 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .pagination button:hover {
    color: #ffc800;
  }
  
  .pagination button:disabled {
    color: #cccccc;
    cursor: not-allowed;
    color: white;
    border-radius: 10% 10%;
    background: black;
  }
  
  .pagination button.active {
    background-color: #333;
    color: white;
    border-radius: 5px;
  }
  
  .pagination span {
    margin: 0 5px;
    font-size: 16px;
    color: #333;
  }
  
  .pagination-ellipsis {
    margin: 0 5px;
    font-size: 16px;
    color: #333;
  }
  
  .pagination button:focus {
    outline: none;
  }
  .pagination button:focus {
    outline: none;
  }
  
