.dt-tableContainer {
  width: 100%;
  max-height: 700px ;
  overflow-x: auto;
  overflow-y: auto;
  margin: 20px 0;
  /* border: 1px solid #ddd; */
  border-radius: 5px;
    /* Standard */

}

.dt-tableContainer .dt-table {
  width: 100%;
  border-collapse: collapse;
  min-width: 600px;
  /* background: red; */
  -webkit-user-select: none; /* For Safari */
  -moz-user-select: none;    /* For Firefox */
  -ms-user-select: none;     /* For Internet Explorer/Edge */
  user-select: none;      

}

.dt-tableContainer .dt-table .header{
  position: sticky;
  top: 0;
}

.dt-tableContainer .dt-table th, .dt-tableContainer td {
  padding: 10px 15px;
  text-align: center;
  border-bottom: 1px solid #eeebeb;
}

.dt-tableContainer thead {
  /* background-color: #f4f4f4 !important;
  color: black !important; */
  height: 55px;
}

.dt-tableContainer thead th {
  font-weight: bold;
}

.dt-tableContainer tbody tr:nth-child(even) {
  /* background-color: #f9f9f9; */
}

.dt-tableContainer tbody tr:hover {
  background-color: #f7f7fa;
}

.dt-tableContainer th,  .dt-tableContainer td {
  white-space: nowrap;
}

@media (max-width: 768px) {
  .dt-tableContainer th, .dt-tableContainer td {
    padding: 8px 10px;
  }
}