.search-container {
    display: flex;
    align-items: center;
    justify-content: flex-end; /* Align the container to the right */
    margin: 8px 0;
    position: relative; /* Position relative for icons */
  }
  
  .search-input {
    width: 20%;
    padding: 6px 40px 6px 20px; /* Add padding to the right for the icons */
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 30px;
    font-size: 16px;
    transition: border-color 0.3s, box-shadow 0.3s, width 0.3s;
  }
  
  .search-input:focus {
    border-color: #ffc800;
    box-shadow: 0 0 8px 0 rgba(76, 175, 80, 0.5);
    outline: none;
    width: 50%; 
    height: 50px;
  }
  
  .cancelIcon, .searchIcon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
    color: #ccc;
    cursor: pointer;
  }
  
  .cancelIcon {
    right: 40px; /* Position of the cancel icon */
  }
  
  .searchIcon {
    right: 10px; /* Position of the search icon */
  }
  