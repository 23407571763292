.App {
  text-align: center;
  -webkit-user-select: none;
  /* For Safari */
  -moz-user-select: none;
  /* For Firefox */
  -ms-user-select: none;
  /* For Internet Explorer/Edge */
  user-select: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.payment-tag {
  display: flex;
  justify-content: center;
}

.dataTableNew {
  position: relative;
}

.DataTable-Container {
  -webkit-user-select: none;
  /* For Safari */
  -moz-user-select: none;
  /* For Firefox */
  -ms-user-select: none;
  /* For Internet Explorer/Edge */
  user-select: none;
}

.blurred-div-wrap {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(2px);
  /* Adjust the blur radius as needed */
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: not-allowed;
}

.oneLine {
  white-space: nowrap !important;
  /* overflow: hidden;       */
  text-overflow: ellipsis;
}

.cancelIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.flex-spaceBetween {
  display: flex;
}

.dropDownEntries {
  flex: 1.5;
  display: flex;
  justify-content: center;
  align-items: center;
  /* cursor: pointer; */
}

.searchBox {
  flex: 9;
}

.labelEntries {
  margin: 0 !important;
  padding-right: 5px;
}

#entries {
  cursor: pointer;
}

.clickToCopy {
  cursor: pointer;
  padding: 5px;
  white-space: nowrap;

}

.clickToCopy:hover {
  border-radius: 5px;
  /* background-color: #ffc800; */
  background-color: #403e3f;
  color: white;

}

.isclickedText{
  position: absolute;
  top: 9%;
  left: 45%;
  z-index: 999999999999;
  background-color: #ffc800;
  color: black;
  text-align: center;
  border-radius: 5px;
  display: inline;
  white-space: nowrap;

  /* transition: opacity 0.3s; */

  }
.actions{
  white-space: nowrap;
}